<template>
  <v-container fluid class="pa-0">
    <v-data-table
      :headers="users_headers"
      :items="clients"
      :options.sync="options"
      :server-items-length="total_clients"
      :multi-sort="false"
      item-key="login"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      :loading="loading_clients"
      loading-text="Загрузка... Подождите"
      @click:row="client_info"
      :item-class="row_class"
      :items-per-page="25"
      :footer-props="{
        'items-per-page-options': [15, 25, 50, 100, -1],
      }"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row>
            <v-col class="col-12 col-md-3"
              ><v-text-field
                label="Номер телефона"
                hide-details
                v-model="number_filter"
                prepend-icon="mdi-magnify"
                clearable
              ></v-text-field
            ></v-col>
            <v-col class="col-12 col-md-3"
              ><v-text-field
                v-model="search"
                hide-details
                label="Поиск по ФИО"
                prepend-icon="mdi-magnify"
                clearable
              ></v-text-field
            ></v-col>
            <v-col class="col-12 col-md-3"
              ><v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Дата последнего входа"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  locale="ru-RU"
                  first-day-of-week="1"
                  @input="menu = false"
                ></v-date-picker> </v-menu
            ></v-col>
            <v-col class="col-12 col-md-1"
              ><v-checkbox
                v-model="tester_filter"
                label="Тестеры"
                clearable
                hide-details
              ></v-checkbox
            ></v-col>
            <v-col class="col-12 col-md-1"
              ><v-checkbox
                v-model="ban_filter"
                label="Заблокированы"
                clearable
                hide-details
              ></v-checkbox
            ></v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.phone="{ item }">
        {{ item.phone != null ? "+" + item.phone : "" }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "List_clients",
  data() {
    return {
      search: null,
      options: {
        sortBy: ["id"],
        sortDesc: [false],
        page: 1,
      },
      number_filter: null,
      tester_filter: false,
      ban_filter: false,
      menu: false,
      date: null,
    };
  },
  created() {
    if (localStorage.getItem("client_filters")) {
      let params = JSON.parse(localStorage.getItem("client_filters"));
      this.number_filter = params.phone ? params.phone : null;
      this.tester_filter = params.testers ? params.testers : false;
      this.ban_filter = params.ban ? params.ban : false;
      this.search = params.search ? params.search : null;
      this.date = params.date ? params.date : null;
      this.options.sortBy = params.sortBy ? params.sortBy : ["id"];
      this.options.sortDesc = params.sortDesc ? params.sortDesc : [false];
      this.options.page = params.page ? params.page : 1;
    } else {
      this.startDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.endDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    }

    this.$store.dispatch("list_clients", this.params);
  },
  watch: {
    params: {
      handler() {
        this.$store.dispatch("list_clients", this.params);
      },
      deep: true,
    },
  },
  computed: {
    params() {
      return {
        ...this.options,
        phone: this.number_filter,
        search: this.search,
        testers: this.tester_filter,
        ban: this.ban_filter,
        date: this.date,
      };
    },
    loading_clients() {
      return this.$store.getters.LOADING_CLIENTS;
    },
    total_clients() {
      return this.$store.getters.TOTAL_CLIENTS;
    },
    users_headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Имя",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Фамилия",
          align: "start",
          sortable: true,
          value: "surname",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Телефон",
          align: "start",
          sortable: true,
          value: "phone",
        },
        {
          text: "Active",
          align: "start",
          sortable: true,
          value: "active",
        },
        {
          text: "Тестер",
          align: "start",
          sortable: true,
          value: "isTester",
        },
        {
          text: "Заблокирован",
          align: "start",
          sortable: true,
          value: "ban",
        },
        {
          text: "Last login",
          align: "start",
          sortable: true,
          value: "current_login_at",
        },
      ];
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search) !== -1
      );
    },
    client_info(item) {
      this.$router.push({
        name: "client_info",
        params: { client_id: item.id },
      });
    },
    row_class() {
      return "clickable";
    },
    getDataFromApi() {
      // this.loading = true;
      this.$store.dispatch("list_clients", this.options);
      // this.fakeApiCall().then((data) => {
      //   this.desserts = data.items;
      //   this.totalDesserts = data.total;
      //   this.loading = false;
      // });
    },
    /**
     * In a real application this would be a call to fetch() or axios.get()
     */
    fakeApiCall() {
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = this.clients;
        const total = items.length;

        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            items,
            total,
          });
        }, 1000);
      });
    },
  },
};
</script>

<style scoped></style>
