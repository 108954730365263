var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.users_headers,"items":_vm.clients,"options":_vm.options,"server-items-length":_vm.total_clients,"multi-sort":false,"item-key":"login","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"loading":_vm.loading_clients,"loading-text":"Загрузка... Подождите","item-class":_vm.row_class,"items-per-page":25,"footer-props":{
      'items-per-page-options': [15, 25, 50, 100, -1],
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.client_info},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-12 col-md-3"},[_c('v-text-field',{attrs:{"label":"Номер телефона","hide-details":"","prepend-icon":"mdi-magnify","clearable":""},model:{value:(_vm.number_filter),callback:function ($$v) {_vm.number_filter=$$v},expression:"number_filter"}})],1),_c('v-col',{staticClass:"col-12 col-md-3"},[_c('v-text-field',{attrs:{"hide-details":"","label":"Поиск по ФИО","prepend-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"col-12 col-md-3"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата последнего входа","prepend-icon":"mdi-calendar","readonly":"","hide-details":"","clearable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"ru-RU","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{staticClass:"col-12 col-md-1"},[_c('v-checkbox',{attrs:{"label":"Тестеры","clearable":"","hide-details":""},model:{value:(_vm.tester_filter),callback:function ($$v) {_vm.tester_filter=$$v},expression:"tester_filter"}})],1),_c('v-col',{staticClass:"col-12 col-md-1"},[_c('v-checkbox',{attrs:{"label":"Заблокированы","clearable":"","hide-details":""},model:{value:(_vm.ban_filter),callback:function ($$v) {_vm.ban_filter=$$v},expression:"ban_filter"}})],1)],1)],1)]},proxy:true},{key:"item.phone",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone != null ? "+" + item.phone : "")+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }